import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { parsePlaceholder } from '../utils';
import { INTERPOLATION_FUNCTION_REGEX, INTERPOLATION_REGEX, FUNCTION_REGEX } from '../../constants';
function parseFunction(_ref) {
  var source = _ref.source,
    data = _ref.data;
  if (typeof data !== 'string') return data;
  var hasFunction = data.includes(':do');
  if (!hasFunction) return data;
  var _data$match = data.match(INTERPOLATION_FUNCTION_REGEX),
    _data$match2 = _slicedToArray(_data$match, 1),
    placeholdersFunction = _data$match2[0];
  var placeholder = placeholdersFunction.replace(FUNCTION_REGEX, '');
  var placeholderFunction = parsePlaceholder([placeholder], placeholder, source);
  if (typeof placeholderFunction !== 'function') return data;
  var _placeholdersFunction = placeholdersFunction.match(FUNCTION_REGEX),
    _placeholdersFunction2 = _slicedToArray(_placeholdersFunction, 1),
    functionData = _placeholdersFunction2[0];
  var placeholderArgs = functionData.replace(':do', '').trim();
  var placeholdersArgs = parsePlaceholder(["{{".concat(placeholderArgs, "}}")], "{{".concat(placeholderArgs, "}}"), source);
  var result = placeholderFunction(placeholdersArgs);
  var dataString = data.replace(INTERPOLATION_FUNCTION_REGEX, result);
  var placeholders = dataString.match(INTERPOLATION_REGEX);
  return parsePlaceholder(placeholders, dataString, source);
}
export default parseFunction;