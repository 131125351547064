import _ from 'lodash';
import useConditionalsRender from '../hooks/useConditionalsRender';

const parseSliceToCondition = (slice, key) => {
  if (_.isPlainObject(slice)) return { [key]: slice };
  if (Array.isArray(slice)) {
    return slice.map(item => {
      if (item && !item.includes('||') && !item.includes('!==')) {
        return `${item}||`;
      }
      return item;
    });
  }
  if (slice && !slice.includes('||') && !slice.includes('!==')) return `${slice}||`;

  return slice;
};

const shouldRender = props => {
  const { slice, data, structure } = props || {};
  let { show, hide } = slice || {};

  show = parseSliceToCondition(show, 'show');
  hide = parseSliceToCondition(hide, 'hide');

  if (show && !useConditionalsRender(show, { ...structure, ...data })) return false;
  if (hide && useConditionalsRender(hide, { ...structure, ...data })) return false;

  return true;
};

export default shouldRender;
