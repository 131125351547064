const redirectFromQuery = logger => ({ structure, data, ctx } = {}) => {
  const { pageRedirect = {} } = data || {};
  const redirectUri = pageRedirect?.redirectUri;
  const currentUri = structure?.resolvedUrl;
  const { ssr = '' } = structure;

  if (redirectUri) {
    logger.info(`[Core] Redirecting by ponzi, from ${currentUri} to ${redirectUri}`);

    if (!ssr) {
      return {
        props: {
          redirect: redirectUri,
        },
      };
    }

    ctx.res.writeHead(301, {
      Location: redirectUri,
    });
    ctx.res.end();
  }

  return null;
};

export default redirectFromQuery;
