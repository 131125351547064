import { parsePlaceholders } from '@magalu/mixer-structure';

const redirectFromEmpty = logger => ({ ctx, structure, data }) => {
  const { redirectIfEmptyData, ssr } = structure;
  const shouldRedirect = data[redirectIfEmptyData?.originData];
  const path = parsePlaceholders(redirectIfEmptyData?.to, structure);

  if (path && !shouldRedirect) {
    if (!ssr) {
      return {
        props: {
          redirect: path,
        },
      };
    }

    logger.debug(`[Core] Redirecting from empty data: ${ctx?.resolvedUrl}`);
    ctx.res.writeHead(302, {
      Location: encodeURI(path),
    });
    ctx.res.end();
  }
  return null;
};

export default redirectFromEmpty;
