/* ###########################################
# Use any text to match with code or reason,
# or a fully object to match with other params
# 
#  Single text example (match code or reason):
# foundError: "SUBREQUEST_HTTP_ERROR"
#
# Match error from service
# foundError: {
#   "service": "beehive"
#   "reason": "request timed out"
# }
#
# Match any error from service
# foundError: {
#   "service": "beehive"
# }
############################################# */

const deepFoundCode = (extension, testData) => {
  if (extension?.extensions) return deepFoundCode(extension.extensions, testData);

  if (typeof testData === 'string')
    return extension?.code === testData || extension?.reason === testData;

  if (Array.isArray(testData))
    return testData.some(test => deepFoundCode(extension, test));

  return Object.entries(testData).every(([key, value]) => extension?.[key] === value);
};

const foundError = (structure, errors) => {
  if (Array.isArray(errors)) {
    const hasError = errors.find(error => {
      if (!structure.foundError) return false;
      return deepFoundCode(error.extensions, structure.foundError);
    });

    if (hasError) {
      const message = hasError?.message || hasError.extensions?.exception?.message;
      throw new Error(
        `${hasError?.extensions?.service || hasError?.path?.join('/')} - ${message}`
      );
    }
  }
};

export default foundError;
