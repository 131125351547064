import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["deeplink"];
var parseDeeplinkParams = function parseDeeplinkParams(_ref) {
  var deeplink = _ref.deeplink,
    params = _objectWithoutProperties(_ref, _excluded);
  var newParams;
  if (!deeplink) {
    return params;
  }
  var decodedDeeplink = decodeURIComponent(deeplink);
  var keyValues = decodedDeeplink.split('&');
  var deeplinkParams = keyValues.reduce(function (acc, item) {
    var _item$split = item.split('='),
      _item$split2 = _slicedToArray(_item$split, 2),
      key = _item$split2[0],
      value = _item$split2[1];
    acc[key] = value;
    return acc;
  }, {});
  if (deeplinkParams.product_sku) {
    newParams = {
      productId: deeplinkParams.product_sku
    };
  }
  if (deeplinkParams.seller_id) {
    newParams = _extends({}, newParams, {
      sellerId: deeplinkParams.seller_id
    });
  }
  return _extends({}, newParams, params);
};
export default parseDeeplinkParams;