export const loadGraphql = () => {
  return import('@magalu/mixer-graphql').catch(e => {
    console.error('[core/utils/loaders] fail to import mixer-graphql', e);
    return {};
  });
};

export const loadModules = () => {
  return import('@magalu/mixer-modules').catch(e => {
    console.error('[core/utils/loaders] fail to import mixer-modules', e);
    return {};
  });
};
