import getConfig from 'next/config';
import { trace } from '@opentelemetry/api';
import { parseCanonical } from '@magalu/mixer-utils';
import { parsePlaceholders } from '@magalu/mixer-structure';

const removeSlugFromCtxQuery = (query = {}) => {
  return Object.entries(query).filter(([entry]) => entry !== 'slug');
};

const redirectCanonicalUrl = logger => ({ ctx, structure, data } = {}) => {
  const { publicRuntimeConfig } = getConfig();
  const span = trace.getActiveSpan();
  const { canonicalPath = '', canonicalUrl = '', ssr } = structure;
  const queries = removeSlugFromCtxQuery(ctx?.query);
  const canonical = parsePlaceholders(canonicalUrl, data).toLowerCase();
  const resolvedPath = canonical && parseCanonical(canonical);

  if (
    canonical &&
    canonicalPath &&
    canonicalUrl &&
    !canonicalPath.includes(resolvedPath) &&
    canonical !== canonicalUrl
  ) {
    const canonicalDomain =
      structure?.config?.canonicalDomain &&
      parsePlaceholders(structure.config.canonicalDomain, {
        config: publicRuntimeConfig,
      }).toLowerCase();

    const resolvedUrl = new URL(resolvedPath, canonicalDomain);
    resolvedUrl.search = new URLSearchParams(queries);

    const path = `${resolvedUrl.pathname}${resolvedUrl.search}`;
    if (!ssr) {
      return {
        props: {
          redirect: path,
        },
      };
    }

    span?.addEvent('mixer.redirect.to.canonicals', {
      'log.message': `Redirect to canonical ${ctx?.resolvedUrl}`,
      'log.severity': 'info',
    });
    logger.info(`[Core] Redirecting to canonical from url: ${ctx?.resolvedUrl}`);

    ctx.res.writeHead(301, {
      Location: path,
    });
    ctx.res.end();
  }

  return null;
};

export default redirectCanonicalUrl;
