import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _typeof from "@babel/runtime/helpers/esm/typeof";
var _excluded = ["required"];
import _ from 'lodash';
import { CONTAINS, EQ, GT, GTE, IF, LT, LTE, NEQ, NOTCONTAINS, NOTEXISTS, EXISTS, SWITCH } from '../constants';

var operations = function operations(_ref) {
  var _CONTAINS$EQ$EXISTS$G;
  var op = _ref.op,
    property = _ref.property,
    _ref$value = _ref.value,
    value = _ref$value === void 0 ? '' : _ref$value,
    output = _ref.output;
  return (_CONTAINS$EQ$EXISTS$G = {}, _defineProperty(_CONTAINS$EQ$EXISTS$G, CONTAINS, (value == null ? void 0 : value.toString().includes(property)) && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, EQ, property === value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, EXISTS, function () {
    if (_typeof(property) !== 'object' && Boolean(property)) {
      return output || true;
    }
    if (_.isObject(property) && !_.isEmpty(property)) {
      return output || true;
    }
    return false;
  }()), _defineProperty(_CONTAINS$EQ$EXISTS$G, GT, property > value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, GTE, property >= value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, LT, property < value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, LTE, property <= value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, NEQ, property !== value && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, NOTCONTAINS, !(value == null ? void 0 : value.toString().includes(property)) && output), _defineProperty(_CONTAINS$EQ$EXISTS$G, NOTEXISTS, function () {
    if (_typeof(property) !== 'object' && !property) {
      return output || true;
    }
    if (_.isObject(property) && _.isEmpty(property)) {
      return output || true;
    }
    return false;
  }()), _CONTAINS$EQ$EXISTS$G)[op];
};

var inventory = function inventory(statement, rules) {
  var _IF$SWITCH$statement;
  return (_IF$SWITCH$statement = {}, _defineProperty(_IF$SWITCH$statement, IF, function () {
    if (rules.conditionals) {
      var results = rules.conditionals.reduce(function (acc, rule) {
        var operation = operations(_extends({}, rule, {
          output: true
        }));
        acc.push(operation);
        return acc;
      }, []);
      return results.every(Boolean) && rules.output;
    }
    if (Array.isArray(rules)) {
      return rules.reduce(function (acc, _ref2) {
        var _ref2$required = _ref2.required,
          required = _ref2$required === void 0 ? true : _ref2$required,
          rule = _objectWithoutProperties(_ref2, _excluded);
        var operation = operations(rule);
        if (operation || required) {
          acc.push(operation);
        }
        return acc;
      }, []);
    }
    return operations(rules);
  }), _defineProperty(_IF$SWITCH$statement, SWITCH, function () {
    var result;
    rules.forEach(function (rule) {
      if (!result) {
        var operation = operations(rule);
        if (operation) {
          result = operation;
        }
      }
    });
    return result;
  }), _IF$SWITCH$statement)[statement];
};

var parseStatements = function parseStatements() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _.transform(data, function (result, value, key) {
    if (_.isPlainObject(value)) {
      var _Object$keys = Object.keys(value),
        _Object$keys2 = _slicedToArray(_Object$keys, 1),
        statement = _Object$keys2[0];
      var fn = inventory(statement, value[statement]);
      result[key] = fn ? fn() : parseStatements(value);
    } else {
      result[key] = value;
    }
  });
};
export { inventory, operations };
export default parseStatements;