import { parsePlaceholders } from '@magalu/mixer-structure';

const useConditionalsRender = (conditionalsRender, data) => {
  if (!conditionalsRender) {
    return true;
  }

  if (Array.isArray(conditionalsRender)) {
    return conditionalsRender
      .reduce((acc, render) => [...acc, parsePlaceholders(render, data)], [])
      .every(render => !!render);
  }
  const parsed = parsePlaceholders(conditionalsRender, data);
  if (parsed.hide !== undefined) {
    return parsed.hide;
  }
  if (parsed.show !== undefined) {
    return parsed.show;
  }
  return !!parsed;
};

export default useConditionalsRender;
