import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { composite } from '@magalu/mixer-utils';
import parseStatements from '../parseStatements';
import { STRING_WITH_PLACEHOLDER_REGEX } from '../constants';
import plugins from './plugins';
import { removeQuotesChar } from './utils';
var parseStringWithPlaceholders = function parseStringWithPlaceholders(data, source) {
  return composite.apply(void 0, _toConsumableArray(plugins))({
    data: data,
    source: source
  });
};
var parseObjectWithPlaceholders = function parseObjectWithPlaceholders(data, source) {
  try {
    var dataString = JSON.stringify(data);
    var allPlaceholders = (dataString.match(STRING_WITH_PLACEHOLDER_REGEX) || []).map(removeQuotesChar);
    if (!allPlaceholders.length) return data;
    var parsedStr = allPlaceholders.reduce(function (acc, placeholder) {
      var value = parseStringWithPlaceholders(placeholder, source);
      return acc.replace("\"".concat(placeholder, "\""), JSON.stringify(value).replace(/\\\\"/g, '"'));
    }, dataString);
    return JSON.parse(parsedStr);
  } catch (e) {
    console.error(e);
    return data;
  }
};
var parsePlaceholders = function parsePlaceholders(data, source) {
  if (!data || !source) return data;
  if (typeof data !== 'string') {
    return parseStatements(parseObjectWithPlaceholders(data, source));
  }
  return parseStringWithPlaceholders(data, source);
};
export default parsePlaceholders;