import getConfig from 'next/config';
import { client as apolloClient } from '@magalu/mixer-graphql';

const requestQuery = async ({ cookies, query, token, variables = {} } = {}) => {
  const config = getConfig();
  const client = apolloClient({ config, cookies, token });
  return client.query({ query, variables });
};

export default requestQuery;
