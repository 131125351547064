import get from 'lodash/get';
import { trace } from '@opentelemetry/api';

const check = (srcString, data) => Boolean(get(data, srcString, false));

const foundCheck = (structure = {}, data = {}) => {
  const span = trace.getActiveSpan();

  if (!structure.foundCheck) return true;
  if (typeof structure.foundCheck === 'string') return check(structure.foundCheck, data);
  if (!Array.isArray(structure.foundCheck)) {
    span?.addEvent('mixer.error.foundCheck', {
      'log.message': `Not found required data from ${structure?.name} page`,
      'log.severity': 'error',
    });
    return true;
  }
  return structure.foundCheck.every(srcString => {
    if (typeof srcString !== 'string') {
      span?.addEvent('mixer.error.foundCheck', {
        'log.message': `Not found required data from ${structure?.name} page`,
        'log.severity': 'error',
      });
      return true;
    }
    return check(srcString, data);
  });
};

export default foundCheck;
