const setErrorStatus = (ctx = {}, status, msg) => {
  if (ctx?.res) ctx.res.statusCode = status;
  return {
    props: {
      errorCode: status,
      errorTitle: msg,
    },
  };
};

export default setErrorStatus;
