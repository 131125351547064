import get from 'lodash/get';
import { PLACEHOLDER_REPLACE_REGEX } from '../../constants';
var encodeHrefPlaceholder = function encodeHrefPlaceholder(placeholder, value, isFullReplacement, acc) {
  if (placeholder === '{{window.location.href}}') {
    var encodedUrl = encodeURIComponent(value);
    return isFullReplacement ? encodedUrl : acc.replace(placeholder, encodedUrl);
  }
  return null;
};
export var parsePlaceholder = function parsePlaceholder(placeholders, dataString, source) {
  return placeholders ? placeholders.reduce(function (acc, placeholder) {
    var value = get(source, placeholder.replace(PLACEHOLDER_REPLACE_REGEX, ''), undefined);
    var isFullReplacement = placeholder === dataString;
    if (value !== undefined) {
      var encodedHref = encodeHrefPlaceholder(placeholder, value, isFullReplacement, acc);
      if (encodedHref !== null) return encodedHref;
      return isFullReplacement ? value : acc.replace(placeholder, value);
    }
    return acc;
  }, dataString) : dataString;
};
export default parsePlaceholder;