import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["sellerId", "seller_id"];
var parseSellerId = function parseSellerId() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sellerId = _ref.sellerId,
    sellerIdQS = _ref.seller_id,
    params = _objectWithoutProperties(_ref, _excluded);
  var parsedParams = _extends({}, params);
  if (sellerId || sellerIdQS) {
    parsedParams.sellerId = sellerId || sellerIdQS;
  }
  return _extends({}, parsedParams);
};
export default parseSellerId;