import { loadModules } from './loaders';

const getComponents = async ({ data }) => {
  const allModules = await loadModules();
  const children = [];
  return (data?.components || data?.serverComponents)?.reduce((result, item) => {
    if (item?.children) {
      item.children.forEach(child =>
        children.push({
          dataSource: child?.dataSource || {},
          module: allModules?.[child.name] || {},
          multiple: !!child.multiple,
          name: child.name,
          queryVariables: child.queryVariables || [],
        })
      );
    }

    if (item.type === 'group') {
      return [
        ...result,
        ...item.components.map(({ name, multiple, queryVariables, dataSource = {} }) => ({
          dataSource,
          module: allModules?.[name] || {},
          multiple,
          name,
          queryVariables: queryVariables || [],
        })),
        ...children,
      ];
    }
    return [
      ...result,
      {
        dataSource: item?.dataSource || {},
        module: allModules?.[item.name] || {},
        multiple: !!item.multiple,
        name: item.name,
        queryVariables: item.queryVariables || [],
      },
      ...children,
    ];
  }, []);
};

export default getComponents;
