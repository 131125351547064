import mergeWith from 'lodash/mergeWith';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { parseQuestionsPageQuery, parseReviewsPageQuery } from '@magalu/mixer-utils';
import parseCategoryId from './parseCategoryId';
import parseFilters from './parseFilters';
import parsePage from './parsePage';
import parsePartnerStoreName from './parsePartnerStoreName';
import parseTerm from './parseTerm';
import parseSellerId from './parseSellerId';
import parseDeeplinkParams from './parseDeeplinkParams';
var parsers = [parseCategoryId, parseFilters, parseTerm, parsePage, parseSellerId, parsePartnerStoreName({
  as: 'pmdStoreName',
  separator: 'magazine'
}), parsePartnerStoreName({
  as: 'pmeStoreName',
  separator: 'parceiro'
}), parseQuestionsPageQuery, parseReviewsPageQuery, parseDeeplinkParams];
var parseRouteData = function parseRouteData(params) {
  var previous = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var routeId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  try {
    var parsedParams = parsers.reduce(function (parsed, parser) {
      return parser(parsed, routeId);
    }, params);
    return mergeWith(parsedParams, previous, function (obj, src) {
      if (Array.isArray(obj)) {
        return uniqWith(obj.concat(src), isEqual);
      }
      return src;
    });
  } catch (e) {
    console.error(e);
    return {};
  }
};
export * from './constants';
export default parseRouteData;