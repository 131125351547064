import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["storeId"];
import { capitalize } from '@magalu/mixer-utils';
var parsePartnerStoreName = function parsePartnerStoreName() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$separator = _ref.separator,
    separator = _ref$separator === void 0 ? 'magazine' : _ref$separator,
    _ref$as = _ref.as,
    as = _ref$as === void 0 ? 'pmdStoreName' : _ref$as;
  return function () {
    var _extends2;
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var storeId = _ref2.storeId,
      params = _objectWithoutProperties(_ref2, _excluded);
    if (!storeId) return params;
    var storeName = capitalize(storeId.split(separator)[1]);
    if (!storeName) return _extends({
      storeId: storeId
    }, params);
    return _extends((_extends2 = {}, _defineProperty(_extends2, as, storeName), _defineProperty(_extends2, "storeId", storeId), _extends2), params);
  };
};
export default parsePartnerStoreName;