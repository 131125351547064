import first from 'lodash/first';
import { parsePlaceholder } from '../utils';
import { INTERPOLATION_REGEX } from '../../constants';
function parseFallback(_ref) {
  var source = _ref.source,
    data = _ref.data,
    _ref$separator = _ref.separator,
    separator = _ref$separator === void 0 ? '||' : _ref$separator;
  if (typeof data !== 'string') return data;
  var hasFallBack = data.includes(separator);
  if (!hasFallBack) return data;
  var fallbacks = data.split(separator);
  var parsedFallbacks = fallbacks.map(function (fallback) {
    var placeholders = fallback.match(INTERPOLATION_REGEX);
    if (!placeholders) return fallback;
    var parsedPlaceholder = parsePlaceholder(placeholders, fallback, source);
    if (parsedPlaceholder !== fallback) return parsedPlaceholder;
    return null;
  });
  var filteredFallback = first(parsedFallbacks.filter(Boolean));
  return filteredFallback || '';
}
export default parseFallback;