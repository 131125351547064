import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["term"];
var termStrParser = function termStrParser(term) {
  if (!term || typeof term !== 'string' || !term.trim()) {
    return '';
  }
  return term.replace(/([^+])\+(?=[^+])/g, '$1 ').replace(/\+{3,}/g, ' + ').replace(/\+{2,}/g, '+').trim();
};
var parseTerm = function parseTerm() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var termStr = _ref.term,
    params = _objectWithoutProperties(_ref, _excluded);
  if (termStr === undefined) return params;
  var term = termStrParser(termStr);
  return _extends({}, params, {
    term: term
  });
};
export default parseTerm;