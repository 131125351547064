import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import replace from 'lodash/replace';
import { parsePlaceholder } from '../utils';
import { INTERPOLATION_REGEX, REPLACE_REGEX } from '../../constants';
function parseReplace(_ref) {
  var source = _ref.source,
    data = _ref.data;
  if (typeof data !== 'string') return data;
  var hasReplace = data.includes(':replace');
  if (!hasReplace) return data;
  var placeholder = data.replace(REPLACE_REGEX, '');
  var placeholders = placeholder.match(INTERPOLATION_REGEX);
  var _data$match = data.match(REPLACE_REGEX),
    _data$match2 = _slicedToArray(_data$match, 1),
    replaceData = _data$match2[0];
  var _replaceData$replace$ = replaceData.replace(':replace', '').trim().split(/\s+/g),
    _replaceData$replace$2 = _slicedToArray(_replaceData$replace$, 2),
    from = _replaceData$replace$2[0],
    _replaceData$replace$3 = _replaceData$replace$2[1],
    to = _replaceData$replace$3 === void 0 ? '' : _replaceData$replace$3;
  var parsedPlaceholder = parsePlaceholder(placeholders, placeholder, source);
  return replace(parsedPlaceholder, from.trim(), to.trim());
}
export default parseReplace;