import { parsePlaceholder } from '../utils';
import { INTERPOLATION_REGEX } from '../../constants';
function parseCommon(_ref) {
  var source = _ref.source,
    data = _ref.data;
  if (typeof data !== 'string') return data;
  var placeholders = data.match(INTERPOLATION_REGEX);
  if (!placeholders) return data;
  return parsePlaceholder(placeholders, data, source);
}
export default parseCommon;