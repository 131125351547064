import { urlCleanFilters } from '@magalu/mixer-utils';

const removeFiltersAndRedirect = (ctx, structure) => {
  const { asPath, rawPath, ssr } = structure;
  const { slug, ...currentQuery } = ctx.query || {};
  const path = urlCleanFilters(asPath, rawPath, currentQuery);
  if (!ssr) {
    return {
      props: {
        redirect: path,
      },
    };
  }

  ctx.res.writeHead(302, {
    Location: path,
  });
  ctx.res.end();
  return {};
};

export default removeFiltersAndRedirect;
