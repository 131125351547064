import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["page"];
import { parsePageFilter } from '@magalu/mixer-utils';
var parsePage = function parsePage() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var currentPage = _ref.page,
    params = _objectWithoutProperties(_ref, _excluded);
  if (!currentPage) return _extends({}, params);
  var page = parsePageFilter(currentPage);
  return _extends({}, params, {
    page: page
  });
};
export default parsePage;