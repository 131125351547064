const setCors = (ctx = {}) => {
  if (ctx.res) {
    const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
    ctx.res.setHeader(
      'Access-Control-Allow-Origin',
      `${protocol}${ctx.req.headers.host}`
    );
    ctx.res.setHeader('Access-Control-Allow-Method', 'GET');
    ctx.res.setHeader('Vary', 'Accept-Encoding, Origin');
  }
};

export default setCors;
