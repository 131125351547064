import { trace } from '@opentelemetry/api';
import deburr from 'lodash/deburr';
import { whereAmI } from '@magalu/mixer-utils';

const rulesFn = {
  lowerCase: (_, path) => {
    const [uri, parameters] = path.split('?');
    const lowerCase = uri.toLowerCase();

    return parameters ? `${lowerCase}?${parameters}` : lowerCase;
  },
  normalize: (_, path) => deburr(path),
  replace: ({ from, to }, path) => path.replace(new RegExp(from, 'g'), to),
};

export const applyRules = (rules, currentPath) => {
  return rules.reduce((acc, rule) => {
    return rulesFn[rule?.name]?.(rule, acc) || acc;
  }, currentPath);
};

/*
  Redirect pages when match in rulesToRedirect from strucutre
  E.g.:
  from: http://localhost:3000/busca/iphone%2012
  to: http://localhost:3000/busca/iphone+12
*/
const redirectFromRules = logger => ({ ctx, structure } = {}) => {
  const span = trace.getActiveSpan();

  const { rulesToRedirect, ssr } = structure;
  if (
    whereAmI.onClient ||
    !ssr ||
    !Array.isArray(rulesToRedirect) ||
    rulesToRedirect.length === 0
  ) {
    return null;
  }

  const url = decodeURIComponent(ctx?.resolvedUrl);
  const path = applyRules(rulesToRedirect, url);

  if (path !== url) {
    span?.addEvent('mixer.redirect.bad.characters', {
      'log.message': `Redirect due invalid characters ${ctx?.resolvedUrl}`,
      'log.severity': 'info',
    });
    logger.info(`[Core] Redirecting from bad character in url: ${ctx?.resolvedUrl}`);
    ctx.res.writeHead(301, {
      Location: encodeURI(path),
    });
    ctx.res.end();
  }
  return null;
};

export default redirectFromRules;
