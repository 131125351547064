import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["filters"];
import { FILTERS_SEPARATOR, FILTER_SLUG_SEPARATOR, FILTER_TYPE_SLUG_SEPARATOR, REVIEW_FILTER, REVIEW_FILTER_MAX, CATEGORY_FILTER, PRICE_FILTER, FILTER_PRICE_SLUG_SEPARATOR } from '@magalu/mixer-utils';
var getReviewMin = function getReviewMin(value) {
  return (value.match(/\d+/g) || []).map(Number).sort(function (a, b) {
    return b - a;
  }).pop() || 0;
};
var getPriceMinMax = function getPriceMinMax(value) {
  return value.split(FILTER_PRICE_SLUG_SEPARATOR).map(Number);
};
var filterStringParser = function filterStringParser(filterString) {
  if (!filterString || typeof filterString !== 'string') {
    return [];
  }
  return filterString.split(FILTERS_SEPARATOR).reduce(function (filters, filter) {
    var _filter$split = filter.split(FILTER_TYPE_SLUG_SEPARATOR),
      _filter$split2 = _slicedToArray(_filter$split, 2),
      type = _filter$split2[0],
      valueStr = _filter$split2[1];
    if (type && valueStr) {
      if (type === PRICE_FILTER) {
        var _getPriceMinMax = getPriceMinMax(valueStr),
          _getPriceMinMax2 = _slicedToArray(_getPriceMinMax, 2),
          minPrice = _getPriceMinMax2[0],
          maxPrice = _getPriceMinMax2[1];
        return [].concat(_toConsumableArray(filters), [{
          max: maxPrice,
          min: minPrice,
          type: type
        }]);
      }
      if (type === REVIEW_FILTER) {
        return [].concat(_toConsumableArray(filters), [{
          max: REVIEW_FILTER_MAX,
          min: getReviewMin(valueStr),
          type: type
        }]);
      }
      var values = valueStr.split(FILTER_SLUG_SEPARATOR);
      var newFilters = values.map(function (value) {
        return {
          type: type,
          value: type === CATEGORY_FILTER ? value.toUpperCase() : value
        };
      });
      return [].concat(_toConsumableArray(filters), _toConsumableArray(newFilters));
    }
    return filters;
  }, []);
};
var parseFilters = function parseFilters() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filterString = _ref.filters,
    params = _objectWithoutProperties(_ref, _excluded);
  var filters = filterStringParser(filterString);
  if (params == null ? void 0 : params.categoryId) {
    filters.push({
      origin: 'url',
      type: 'category',
      value: params.categoryId.toUpperCase()
    });
  }
  if (params == null ? void 0 : params.subCategoryId) {
    filters.push({
      origin: 'url',
      type: 'subcategory',
      value: params.subCategoryId.toUpperCase()
    });
  }
  if (params == null ? void 0 : params.brandId) {
    filters.push({
      origin: 'url',
      type: 'brand',
      value: params.brandId.toLowerCase()
    });
  }
  if (params == null ? void 0 : params.sellerId) {
    filters.push({
      origin: 'url',
      type: 'seller',
      value: params.sellerId
    });
  }
  if (params == null ? void 0 : params.selectionId) {
    filters.push({
      origin: 'url',
      type: 'selection',
      value: params.selectionId
    });
  }
  if (filterString === undefined && !filters.length) return params;
  return _extends({}, params, {
    filters: filters
  });
};
export default parseFilters;