import { parsePlaceholders } from '@magalu/mixer-structure';

const redirectToParent = (ctx, structure) => {
  const { ssr } = structure;
  const { notfoundFallbackUrl } = structure;
  const path = parsePlaceholders(notfoundFallbackUrl, structure);
  if (!ssr) {
    return {
      props: {
        redirect: path?.toLowerCase(),
      },
    };
  }

  ctx.res.writeHead(302, {
    Location: path?.toLowerCase(),
  });
  ctx.res.end();

  return {};
};

export default redirectToParent;
