export var CONTAINS = 'contains';
export var EQ = 'eq';
export var EXISTS = 'exists';
export var FUNCTION_REGEX = /\s+(:do)\s\w+(\.)?\w+[^}]/g;
export var GT = 'gt';
export var GTE = 'gte';
export var IF = '@if';
export var INTERPOLATION_FUNCTION_REGEX = /\{\{\w+(\.)?\w+\s+(:do)\s\w+(\.)?\w+[^}]\}\}/g;
export var INTERPOLATION_REGEX = /\{\{[^}]+\}\}/g;
export var LT = 'lt';
export var LTE = 'lte';
export var NEQ = 'neq';
export var NOTCONTAINS = 'notcontains';
export var NOTEXISTS = 'notexists';
export var PLACEHOLDER_REPLACE_REGEX = /(\{|\})/g;
export var REPLACE_REGEX = /\s*(:replace).*[^}]{2}/g;
export var STRING_WITH_PLACEHOLDER_REGEX = /"([^"]+)?(\\")?(\{{2}[^"]+\}{2})(\\")?([^"]+)?"/g;
export var SWITCH = '@switch';