import parseAnd from './parseAnd';
import parseCommon from './parseCommon';
import parseDifference from './parseDifference';
import parseFallback from './parseFallback';
import parseReplace from './parseReplace';
import parseFunction from './parseFunction';
var plugins = [[parseDifference, {
  separator: '!=='
}], [parseFallback, {
  separator: '||'
}], [parseAnd, {
  separator: '&&'
}], parseFunction, parseReplace, parseCommon];
export default plugins;