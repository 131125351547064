class QueryDefinitionCleaner {
  constructor() {
    this.definedVariables = [];
    this.definedDefinitions = [];
  }

  removeDuplicatedVariables(variableDefinitions) {
    return variableDefinitions.filter(variableDefinition => {
      const variableName = variableDefinition?.variable?.name?.value;

      if (!variableName || this.definedVariables.includes(variableName)) {
        return false;
      }

      this.definedVariables.push(variableName);
      return true;
    });
  }

  removeDuplicated(definition) {
    const definitionKey = `${definition?.kind}|${definition?.name?.value}`;
    if (this.definedDefinitions.includes(definitionKey)) {
      return false;
    }
    this.definedDefinitions.push(definitionKey);
    return true;
  }

  clean(query = {}) {
    const definitions =
      query?.definitions?.filter(this.removeDuplicated.bind(this))?.map(definition => ({
        ...definition,
        variableDefinitions: definition?.variableDefinitions?.length
          ? this.removeDuplicatedVariables(definition.variableDefinitions)
          : [],
      })) || [];
    return { ...query, definitions };
  }
}

export default QueryDefinitionCleaner;
