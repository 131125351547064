import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { parsePlaceholder } from '../utils';
import { INTERPOLATION_REGEX } from '../../constants';
function parseDifference(_ref) {
  var source = _ref.source,
    data = _ref.data,
    _ref$separator = _ref.separator,
    separator = _ref$separator === void 0 ? '!==' : _ref$separator;
  if (typeof data !== 'string') return data;
  var hasDifference = data.includes(separator);
  if (!hasDifference) return data;
  var differences = data.split(separator);
  var parsedDifference = differences.map(function (difference) {
    var placeholders = difference.match(INTERPOLATION_REGEX);
    if (!placeholders) return difference;
    return parsePlaceholder(placeholders, difference, source);
  });
  return uniqWith(parsedDifference, isEqual).length > 1;
}
export default parseDifference;