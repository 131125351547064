import { useEffect } from 'react';
import get from 'lodash/get';
import { whereAmI } from '@magalu/mixer-utils';

const usePrivateRoute = structure => {
  if (whereAmI.onServer) return;

  useEffect(() => {
    const loggedUserDataPath = structure?.config?.loggedUser;
    const loginUrl = structure?.config?.loginUrl;
    if (structure.private && loggedUserDataPath && loginUrl) {
      const loggedUser = get(structure, loggedUserDataPath);
      if (!loggedUser?.id) {
        window.location.href = loginUrl + window.location.href;
      }
    }
  }, [JSON.stringify(structure)]);
};

export default usePrivateRoute;
