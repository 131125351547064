import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["categoryId", "subCategoryId"];
var parseCategoryId = function parseCategoryId() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var categoryId = _ref.categoryId,
    subCategoryId = _ref.subCategoryId,
    params = _objectWithoutProperties(_ref, _excluded);
  var parsedParams = _extends({}, params);
  if (categoryId) {
    parsedParams.categoryId = categoryId.toUpperCase();
  }
  if (subCategoryId) {
    parsedParams.subCategoryId = subCategoryId.toUpperCase();
  }
  return _extends({}, parsedParams);
};
export default parseCategoryId;