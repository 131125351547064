import getComponents from '../../utils/getComponents';
import buildQuery, { buildVariables } from '../../utils/buildQuery';
import requestQuery from '../../utils/requestQuery';

const useData = async ({ structure, token }) => {
  const { cookies } = structure;
  const modules = await getComponents({ data: structure });
  const variables = buildVariables({ modules, structure });
  const { document: query } = await buildQuery({ modules });
  const { data = {}, errors } = query
    ? await requestQuery({ cookies, query, token, variables })
    : {};
  return { data, errors };
};

export default useData;
