import { parsePlaceholder } from '../utils';
import { INTERPOLATION_REGEX } from '../../constants';
function parseAnd(_ref) {
  var source = _ref.source,
    data = _ref.data,
    _ref$separator = _ref.separator,
    separator = _ref$separator === void 0 ? '&&' : _ref$separator;
  if (typeof data !== 'string') return data;
  var hasAnd = data.includes(separator);
  if (!hasAnd) return data;
  var items = data.split(separator);
  var parsedItems = items.map(function (item) {
    var placeholders = item.match(INTERPOLATION_REGEX);
    if (!placeholders) return item;
    var parsedPlaceholder = parsePlaceholder(placeholders, item, source);
    if (parsedPlaceholder !== item) return parsedPlaceholder;
    return null;
  });
  return parsedItems.every(Boolean);
}
export default parseAnd;