import merge from 'lodash/merge';

var deepMergeCustom = function deepMergeCustom(objValue, srcValue) {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return objValue
    .map(function (objEl) {
      var sameIdSrcEl = srcValue.find(function (srcEl) {
        return srcEl.id === objEl.id;
      });
      if (sameIdSrcEl) return merge(objEl, sameIdSrcEl);
      return objEl;
    })
    .concat(srcValue.filter(function (sEl) {
      return !objValue.find(function (e) {
        return e.id === sEl.id;
      });
    }));
  }
  return undefined;
};
export default deepMergeCustom;